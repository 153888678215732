<template>
    <div>
        <p class="text text-margin">
            {{ $t('faq.flexIptv.tvGuide.titles.step5') }}
        </p>


        <div>
            <p class="text">{{ $t('faq.flexIptv.tvGuide.step5.regularVersion')}}</p>
            <a :href="epgUrl" target="_blank" :rel="'noreferrer noopener'" class="link">{{ epgUrl }}</a>
        </div>

        <div class="screenshot">
            <img src="../../assets/tvGuide/screen-5.png" :alt="$t('faq.flexIptv.tvGuide.titles.step5')" :width="318" :height="265" class='step-image'>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['brandConfig']),
        epgUrl() {
            const tvLinkWithoutHttps = this.brandConfig.tvLink.replace('https://', '');
            return `https://epg.${tvLinkWithoutHttps}/utc.xml.gz`
        }
    }
}
</script>

<style scoped>
@import "../../../../components/styles/guides-default-styles.css";
</style>
